






























import { Vue, Component } from 'vue-property-decorator'
import BadgeCard from '@/components/Gamification/BadgeCard.vue'
import { useGamificationMyBadges } from '@/hooks/gamification'

@Component({
  components: { BadgeCard },
  setup() {
    const { data: badgeData, isLoading: isBadgesLoading } = useGamificationMyBadges()

    return {
      badgeData,
      isBadgesLoading,
    }
  },
})
export default class Badges extends Vue {
  badgeData!: any
  isBadgesLoading!: boolean

  async mounted() {}

  badgeListing() {
    window.open('/badges', '_blank')
  }
}
