

















































import { Vue, Component } from 'vue-property-decorator'

import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import Rewards from '@/components/Gamification/Rewards.vue'
import Badges from '@/components/Gamification/Badges.vue'
import Leaderboard from '@/components/Gamification/Leaderboard.vue'

@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
    TheTitle,
    Rewards,
    Badges,
    Leaderboard,
  },
})
export default class ViewTags extends Vue {
  currentTab: 'REWARDS' | 'BADGES' | 'LEADERBOARD' = 'REWARDS'

  get filterConfig() {
    return {
      REWARDS: 'REWARDS',
      BADGES: 'BADGES',
      LEADERBOARD: 'LEADERBOARD',
    }
  }
}
