var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _c("TheSideNavigation"),
          _c(
            "div",
            { staticClass: "limiter scrollbar" },
            [
              _c("TheTitle", {
                attrs: { icon: "verified", title: "Rewards", large: true },
              }),
              _c("div", { staticClass: "flex items-center silver" }),
              _vm.currentTab === _vm.filterConfig.REWARDS
                ? _c("Rewards")
                : _vm._e(),
              _vm.currentTab === _vm.filterConfig.BADGES
                ? _c("Badges")
                : _vm._e(),
              _vm.currentTab === _vm.filterConfig.LEADERBOARD
                ? _c("Leaderboard")
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }