



















































































import { Vue, Component } from 'vue-property-decorator'
import moment from 'moment'
import get from 'lodash.get'
import { Getter } from 'vuex-class'

import { useGamificationMyScore, useGamificationLeaderboard } from '@/hooks/gamification'

@Component({
  components: {},
  setup() {
    const { data: scoreData, isLoading: isScoreLoading } = useGamificationMyScore()
    const { data: leaderboardData, isLoading: isLeaderboardLoading } = useGamificationLeaderboard()

    return {
      scoreData,
      isScoreLoading,
      leaderboardData,
      isLeaderboardLoading,
    }
  },
})
export default class Rewards extends Vue {
  @Getter networkType!: string

  scoreData!: any
  isScoreLoading!: boolean

  leaderboardData!: any
  isLeaderboardLoading!: boolean

  get isOfferApplicable() {
    return ['YOUTUBE_PRO'].includes(this.networkType)
  }
  get topCreator() {
    if (this.leaderboardData.length) {
      return this.leaderboardData[0]
    }
  }

  get daysLeft() {
    const d = moment()
    const currentDay = d.get('date')
    const daysInMonth = d.daysInMonth()
    const remainingDays = daysInMonth - currentDay
    return remainingDays
  }

  get currentMonth() {
    const currentDate = moment()
    const currentMonth = currentDate.month()
    const currentYear = currentDate.year()

    const startDate = moment([currentYear, currentMonth, 1])
    const endDate = moment(startDate).endOf('month')

    return startDate.format('MMM. D') + ' - ' + endDate.format('MMM. D, YYYY')
  }

  get rank() {
    return get(this, 'scoreData.rank', 'NA') ? this.addOrdinalSuffix(get(this, 'scoreData.rank', 'NA')) : 'NA'
  }

  get score() {
    return get(this, 'scoreData.score', 'NA')
  }

  addOrdinalSuffix(rank: any) {
    let j = rank % 10,
      k = rank % 100
    if (j == 1 && k != 11) {
      return rank + 'st'
    }
    if (j == 2 && k != 12) {
      return rank + 'nd'
    }
    if (j == 3 && k != 13) {
      return rank + 'rd'
    }
    return rank + 'th'
  }

  rankBgColor(rank: any) {
    return {
      'bg-yellow': rank == '1',
      'bg-teal': rank == '2',
      'bg-orange': rank == '3',
    }
  }
  rankTextColor(rank: any) {
    return {
      'text-yellow': rank == '1',
      'text-teal': rank == '2',
      'text-orange': rank == '3',
    }
  }
}
