var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "grid" }, [
      _vm.isScoreLoading
        ? _c("div", { staticClass: "c-card skeleton left-size" })
        : _vm._e(),
      _vm.isLeaderboardLoading
        ? _c("div", { staticClass: "c-card skeleton right-size" })
        : _vm._e(),
    ]),
    _c("div", { staticClass: "grid" }, [
      !_vm.isScoreLoading
        ? _c("div", { staticClass: "grid-item" }, [
            _c("div", { staticClass: "c-card rank-bg" }, [
              _c("div", { staticClass: "c-card-body" }, [
                _c("div", { staticClass: "flex justify-between" }, [
                  _c("div", { staticClass: "tl" }, [
                    _c("div", [_vm._v("My Rank")]),
                    _c("h2", [_vm._v(_vm._s(_vm.rank) + " Place")]),
                  ]),
                  _c("div", { staticClass: "tr" }, [
                    _c("div", [_vm._v("My Score")]),
                    _c("h2", [_vm._v(_vm._s(_vm.score))]),
                  ]),
                ]),
              ]),
            ]),
            _vm.leaderboardData.length
              ? _c("div", { staticClass: "c-card" }, [
                  _c("div", { staticClass: "c-card-body" }, [
                    _c("div", { staticClass: "tc" }, [
                      _c("div", { staticClass: "off-white mb3 f3" }, [
                        _vm._v("Creator of the Month"),
                      ]),
                      _vm.topCreator.profileImage
                        ? _c("img", {
                            staticClass: "c-avatar c-avatar-lg",
                            attrs: {
                              src: _vm.topCreator.profileImage.originalUrl,
                            },
                          })
                        : _c("img", {
                            staticClass: "c-avatar c-avatar-lg",
                            attrs: {
                              src: require("@/assets/gamification/default-profile.png"),
                            },
                          }),
                      _c("h3", [_vm._v(_vm._s(_vm.topCreator.name))]),
                      _vm.isOfferApplicable
                        ? _c("span", { staticClass: "text-win" }, [
                            _vm._v(
                              "⌛ " +
                                _vm._s(_vm.daysLeft) +
                                " days left to win $25"
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      !_vm.isLeaderboardLoading
        ? _c("div", { staticClass: "grid-item" }, [
            _c("div", { staticClass: "c-card" }, [
              _c("div", { staticClass: "c-card-header" }, [
                _c("h3", [_vm._v("LEADERBOARD")]),
                _c("span", [_vm._v(_vm._s(_vm.currentMonth))]),
              ]),
              _c("div", { staticClass: "c-card-body" }, [
                _c(
                  "ul",
                  { staticClass: "c-list" },
                  [
                    _vm._m(0),
                    _vm._l(_vm.leaderboardData, function (creator) {
                      return _c("li", { staticClass: "c-list-item" }, [
                        _c("div", { staticClass: "c-list-grid" }, [
                          _c(
                            "div",
                            {
                              staticClass: "c-flag bg-transparent",
                              class: _vm.rankBgColor(creator.rank),
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(creator.rank) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "inline-flex items-center" },
                            [
                              creator.profileImage
                                ? _c("img", {
                                    staticClass: "c-avatar",
                                    attrs: {
                                      src: creator.profileImage.thumbnailURL,
                                    },
                                  })
                                : _c("img", {
                                    staticClass: "c-avatar",
                                    attrs: {
                                      src: require("@/assets/gamification/default-profile.png"),
                                    },
                                  }),
                              _c("div", { staticClass: "c-name" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(creator.name) +
                                    "\n                  "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "tr",
                              class: _vm.rankTextColor(creator.rank),
                            },
                            [
                              _c("strong", [
                                _vm._v(_vm._s(creator.total_points)),
                              ]),
                              creator.rank == "1"
                                ? _c("span", [_vm._v(" 🏆")])
                                : _vm._e(),
                              creator.rank == "2"
                                ? _c("span", [_vm._v(" ⭐️")])
                                : _vm._e(),
                              creator.rank == "3"
                                ? _c("span", [_vm._v(" 💯")])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "c-list-item" }, [
      _c("div", { staticClass: "c-list-grid" }, [
        _c("div", { staticClass: "tl off-white" }, [_vm._v("Rank")]),
        _c("div", { staticClass: "tl off-white" }, [_vm._v("Creator Name")]),
        _c("div", { staticClass: "tr off-white" }, [_vm._v("Points")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }