var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isOfferApplicable && _vm.isInvoicesLoading
      ? _c("div", {
          staticClass: "mt3 bg-adori-medium-gray br3 w-100 skeleton top-size",
        })
      : _vm._e(),
    _vm.isInvoicesLoading
      ? _c("div", {
          staticClass:
            "mt3 bg-adori-medium-gray mt1 br3 w-100 skeleton bottom-size",
        })
      : _c("div", [
          _vm.isOfferApplicable
            ? _c(
                "div",
                {
                  staticClass:
                    "flex flex-wrap mt3 ph1 pv3 link light-gray bg-adori-light-gray bb b--adori-gray br2 relative",
                },
                [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "w-30 flex flex-column justify-center" },
                    [
                      _c("div", { staticClass: "f5 silver" }, [
                        _vm._v("Complete 3000 minutes to get $25 Gift card"),
                      ]),
                      _c(
                        "div",
                        {},
                        [
                          _c("ProgressBar", {
                            attrs: {
                              options: _vm.options,
                              value: _vm.progressBarValue,
                              max: "3000",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "flex flex-column mt3 ph4 pv3 link light-gray bg-adori-light-gray bb b--adori-gray br2 relative",
            },
            [
              _c(
                "div",
                { staticClass: "f3 fw1 mr4 light-gray flex items-center ph2" },
                [_vm._v("Exciting news! 🚀")]
              ),
              _c("p", { staticClass: "silver f5 lh-wide select-text ph2" }, [
                _vm._v(
                  "\n        We're thrilled that you're enjoying your time with Adori and we want to show our appreciation by offering you\n        two free video download/upload for sharing your experience! Plus, one lucky member will win a premium\n        membership every month! 🎉\n      "
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "f5 fw1 mr4 light-gray flex items-center ph2 pv2",
                },
                [
                  _vm._v(
                    "\n        Here's how you can claim your rewards in just two simple steps 👇\n      "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "f4 fw1 mr4 light-gray flex items-center ph2 pt2",
                },
                [_vm._v("Step 1:")]
              ),
              _c("p", { staticClass: "silver f5 lh-wide select-text ph2" }, [
                _vm._v(
                  "Share your genuine review on any (or all!) of these platforms:"
                ),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  { staticClass: "share", on: { click: _vm.postToLinkedIn } },
                  [
                    _vm._m(1),
                    _c("div", { staticClass: "text" }, [_vm._v("LinkedIn")]),
                  ]
                ),
                _vm._v("\n        -->\n        "),
                _c(
                  "div",
                  { staticClass: "share", on: { click: _vm.postToTrustpilot } },
                  [
                    _vm._m(2),
                    _c("div", { staticClass: "text" }, [_vm._v("Trustpilot")]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "share", on: { click: _vm.postToG2C } },
                  [
                    _vm._m(3),
                    _c("div", { staticClass: "text" }, [_vm._v("G2 Crowd")]),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "f4 fw1 mr4 light-gray flex items-center ph2 pt4",
                },
                [_vm._v("Step 2:")]
              ),
              _vm._m(4),
              _c("iframe", {
                attrs: {
                  "data-tally-src":
                    "https://tally.so/embed/w8NVjP?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1",
                  loading: "lazy",
                  width: "100%",
                  height: "525",
                  frameborder: "0",
                  marginheight: "0",
                  marginwidth: "0",
                  title: "Free quota",
                },
                on: { load: _vm.loadIframe },
              }),
            ]
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-10 justify-center" }, [
      _c("img", {
        staticClass: "mr2",
        attrs: {
          src: require("@/assets/gamification/wizard.png"),
          width: "200",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "linkedin-icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/social-share/linkedin.svg") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "business-icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/social-share/trustpilot.svg") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "business-icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/social-share/g2crowd.svg") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "silver f5 lh-wide select-text ph2" }, [
      _vm._v(
        "\n        Once your reviews are posted, simply upload screenshots and share the links with us. We'll verify your reviews\n        and then boost your free quota.. "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n        Verification typically takes up to 3 working days. Don't worry, we'll keep you updated via email throughout\n        the process. 😊\n      "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }