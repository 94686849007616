var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isBadgesLoading
      ? _c("div", {
          staticClass: "mt3 bg-adori-medium-gray br3 w-100 skeleton top-size",
        })
      : _c("div", [
          _vm.badgeData.length
            ? _c(
                "div",
                { attrs: { id: "container" } },
                _vm._l(_vm.badgeData, function (badge) {
                  return _c("BadgeCard", {
                    key: badge.enum,
                    attrs: {
                      title: badge.title,
                      content: badge.description,
                      points: badge.points,
                      count: badge.count,
                      imageUrl: badge.imageUrl,
                    },
                  })
                }),
                1
              )
            : _c("div", [
                _c(
                  "div",
                  { staticClass: "empty" },
                  [
                    _c("img", {
                      attrs: {
                        width: "150px",
                        alt: "placeholder",
                        src: require("@/assets/gamification/no-badge.png"),
                      },
                    }),
                    _c("div", { staticClass: "text white" }, [
                      _vm._v("You've not earned any badge yet"),
                    ]),
                    _c("span", [
                      _vm._v(
                        "Complete any achievement/milestone to unlock badges "
                      ),
                    ]),
                    _c("BaseButtonRed", {
                      staticClass: "bw0 fr",
                      attrs: {
                        text: "All Badges Available",
                        onClick: _vm.badgeListing,
                      },
                    }),
                  ],
                  1
                ),
              ]),
        ]),
    !_vm.isBadgesLoading && _vm.badgeData.length
      ? _c("div", { staticClass: "flex justify-center mt3" }, [
          _c(
            "a",
            {
              staticClass: "blue link",
              attrs: { href: "/badges", target: "_blank" },
            },
            [_vm._v("Learn more about all badges available")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }